import React, { useState, useEffect } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from "../assets/logoT.png";
import backgroundImage from "../assets/herosection2.jpg"; // Substitua pelo caminho da sua imagem

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='home'
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <header className={`fixed top-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-amarelo shadow-md py-1' : 'bg-transparent py-4'}`}>
        <div className="container mx-auto flex justify-between items-center p-4">
          <div className="flex items-center space-x-4">
            <img src={logo} alt="Logo" className="w-12 h-12" /> 
            <span className={`text-2xl font-bold ${isScrolled ? 'text-danger' : 'text-white'}`}>Bangu Odonto Clínica</span>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="#home" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Home</a>
            <a href="#services" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Serviços</a>
            <a href="#team" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Equipe</a>
            <a href="#about" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Sobre</a>
            <a href="#appointment" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Marque uma consulta</a>
            <a href="#contact" className="text-white bg-azulescuro hover:bg-azulclaro hover:text-white py-2 px-4 rounded-md">Contatos</a>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <a href="https://www.facebook.com/banguodontoclinica" target='_blank' className={`text-white hover:text-azulescuro ${isScrolled ? 'text-azulescuro' : 'text-white'}`}>
              <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
            </a>
            <a href="https://www.instagram.com/banguodontoclinica" target='_blank' className={`text-white hover:text-azulescuro ${isScrolled ? 'text-azulescuro' : 'text-white'}`}>
              <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
            </a>
            <a href="https://www.google.com/maps" className={`text-white hover:text-azulescuro ${isScrolled ? 'text-azulescuro' : 'text-white'}`}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="h-6 w-6" />
            </a>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <XIcon className="h-6 w-6 text-azulescuro" /> : <MenuIcon className="h-6 w-6 text-azulescuro" />}
            </button>
          </div>
        </div>
        <nav className={`${isOpen ? 'block' : 'hidden'} md:hidden bg-white`}>
          <ul className="flex flex-col items-center space-y-4 py-4">
            <li>
              <a href="#home" className="text-azulescuro hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Home</a>
            </li>
            <li>
              <a href="#services" className="text-gray-600 hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Serviços</a>
            </li>
            <li>
              <a href="#team" className="text-gray-600 hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Equipe</a>
            </li>
            <li>
              <a href="#about" className="text-gray-600 hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Sobre</a>
            </li>
            <li>
              <a href="#appointment" className="text-gray-600 hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Marque uma consulta</a>
            </li>
            <li>
              <a href="#contact" className="text-gray-600 hover:bg-azulescuro hover:text-white py-2 px-4 block rounded-md">Contatos</a>
            </li>
            <li className="flex space-x-4">
              <a href="https://wa.me/5521978982711" className="text-gray-600 hover:text-azulescuro">
                <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6" />
              </a>
              <a href="https://www.facebook.com/banguodontoclinica" target='_blank' className="text-gray-600 hover:text-azulescuro">
                <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
              </a>
              <a href="https://www.instagram.com/banguodontoclinica" target='_blank' className="text-gray-600 hover:text-azulescuro">
                <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
              </a>
              <a href="https://www.google.com/maps" className="text-gray-600 hover:text-azulescuro">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="h-6 w-6" />
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="absolute inset-0 flex items-center justify-end p-4">
        <div className="text-right text-white mr-8">
          <h1 className="text-3xl md:text-5xl font-bold leading-tight text-azulescuro">
            Atendimento humanitário <br /> e com máxima qualidade
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
