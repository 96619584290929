import React from 'react';
import { FaTooth, FaSmile, FaTeethOpen, FaSyringe, FaClipboardCheck, FaFillDrip, FaCapsules, FaSun, FaCut, FaBandAid, FaUserMd } from 'react-icons/fa';

const tratamentos = [
  {
    icon: <FaTooth />,
    title: 'Implante Dentário',
    description: 'Implantes de alta qualidade para melhorar seu sorriso e funcionalidade dental.',
  },
  {
    icon: <FaClipboardCheck />,
    title: 'Prótese sobre Implante',
    description: 'Prótese fixa sobre implante para maior segurança e conforto.',
  },
  {
    icon: <FaSmile />,
    title: 'Prótese Fixa',
    description: 'Soluções de próteses fixas para restauração do seu sorriso.',
  },
  {
    icon: <FaCapsules />,
    title: 'Prótese Removível',
    description: 'Prótese removível para flexibilidade e conveniência.',
  },
  {
    icon: <FaSmile />,
    title: 'Prótese Flexível',
    description: 'Prótese flexível para maior conforto e adaptabilidade.',
  },
  {
    icon: <FaSun />,
    title: 'Prótese Total',
    description: 'Próteses totais para reabilitação completa do sorriso.',
  },
  {
    icon: <FaTeethOpen />,
    title: 'Faceta Dentária',
    description: 'Melhore a estética do seu sorriso com facetas dentárias personalizadas.',
  },
  {
    icon: <FaSmile />,
    title: 'Aparelho Ortodôntico',
    description: 'Correção ortodôntica com aparelhos convencionais ou estéticos.',
  },
  {
    icon: <FaSmile />,
    title: 'Alinhador Invisível',
    description: 'Aparelho ortodôntico invisível para um tratamento discreto e eficaz.',
  },
  {
    icon: <FaFillDrip />,
    title: 'Clareamento Dentário',
    description: 'Clareamento dental seguro e eficaz para um sorriso mais brilhante.',
  },
  {
    icon: <FaSyringe />,
    title: 'Tratamento de Canal',
    description: 'Tratamento de canal sem dor para garantir a saúde e preservação dos seus dentes.',
  },
  {
    icon: <FaCut />,
    title: 'Cirurgia',
    description: 'Procedimentos cirúrgicos dentários realizados com precisão e cuidado.',
  },
  {
    icon: <FaBandAid />,
    title: 'Restaurações',
    description: 'Restaurações dentárias de alta qualidade para um sorriso perfeito.',
  },
];

const TratamentoList = () => {
  return (
    <div className="bg-white py-12" id="services">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Serviços Realizados na Clínica</h2>
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap">
            {tratamentos.map((tratamento, index) => (
              <div key={index} className="inline-block w-64 p-4">
                <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center">
                  <div className="h-12 w-12 text-primary mb-4 text-4xl mx-auto">
                    {tratamento.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-2">{tratamento.title}</h3>
                  <p className="text-gray-700">{tratamento.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ClinicaApp = () => {
  return (
    <div>
      
      <main>
        <TratamentoList />
      </main>
    </div>
  );
};

export default ClinicaApp;

// CSS para ocultar a barra de rolagem
const hideScrollBarStyle = `
.hide-scroll-bar {
  -ms-overflow-style: none; /* IE e Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, e Opera */
}
`;

// Adicionando estilo global ao documento
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = hideScrollBarStyle;
document.head.appendChild(styleSheet);
