import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-azulescuro text-white p-8" id='contact'>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h2 className="text-2xl font-bold">Bangu Odonto Clínica</h2>
            <p className="text-gray-400">Avenida Santa Cruz, 4490. Bangu. CEP: 21810-025.</p>
            <p className="text-gray-400">
              <FontAwesomeIcon icon={faPhone} /> 21 978982711 (WhatsApp)
            </p>
            <p className="text-gray-400">
              <FontAwesomeIcon icon={faEnvelope} /> banguodontoclinica@gmail.com
            </p>
          </div>
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h2 className="text-2xl font-bold">Links Úteis</h2>
            <ul className="space-y-2">
              <li>
                <a href="#home" className="text-gray-400 hover:text-white">Home</a>
              </li>
              <li>
                <a href="#services" className="text-gray-400 hover:text-white">Serviços</a>
              </li>
              <li>
                <a href="#team" className="text-gray-400 hover:text-white">Equipe</a>
              </li>
              <li>
                <a href="#appointment" className="text-gray-400 hover:text-white">Marque uma Consulta</a>
              </li>
              <li>
                <a href="#contact" className="text-gray-400 hover:text-white">Contatos</a>
              </li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h2 className="text-2xl font-bold">Siga-nos</h2>
            <div className="flex justify-center md:justify-start space-x-4 mt-2">
              <a href="https://www.facebook.com" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
              </a>
              <a href="https://www.instagram.com" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
              </a>
              <a href="https://www.twitter.com" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-400">
          &copy; {new Date().getFullYear()} Bangu Odonto Clínica. Todos os direitos reservados.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
