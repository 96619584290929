import React from 'react';

const Appointment = () => {
  const phoneNumber = "5521978982711"; // Substitua pelo número do WhatsApp desejado
  const message = "Olá, gostaria de marcar uma consulta."; // Mensagem pré-definida

  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="bg-gray-100 py-12" id='appointment'>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Marque uma Consulta</h2>
        <div className="flex justify-center">
          <button
            onClick={handleWhatsAppClick}
            className="bg-azulclaro hover:bg-secondary text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
          >
            Clique aqui para marcar uma consulta
          </button>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
