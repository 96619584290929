import React from 'react';
import marcio from "../assets/marcio1.png";
import team from "../assets/equipe.jpg"

const teamMembers = [
  {
    name: 'Dr. Marcio do Valle',
    title: 'Dentista',
    description: 'Especialista em ortodontia e implantes dentários com mais de 15 anos de experiência.',
    image: marcio, // Substitua pelo caminho da imagem
  },
  {
    name: 'Team Bangu Odonto Clínica',
    title: 'Nossa Equipe',
    description: 'Profissionais altamente capacitados e extremamente simpáticos.',
    image: team, // Substitua pelo caminho da imagem
  },
  
];

const Team = () => {
  return (
    <div className="bg-azulescuro py-12" id='team'>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-8">Nossa Equipe</h2>
        <div className="flex flex-wrap justify-center">
          {teamMembers.map((member, index) => (
            <div key={index} className="w-full md:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-32 h-32 mx-auto rounded-xl mb-4 object-cover"
                />
                <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                <p className="text-gray-600 mb-2">{member.title}</p>
                <p className="text-gray-700">{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
