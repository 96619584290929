import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/5521978982711" // Substitua pelo número do WhatsApp desejado
      className="fixed bottom-4 right-4 p-4 rounded-full shadow-lg whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faWhatsapp} className="h-16 w-16 text-white" />
    </a>
  );
};

export default WhatsAppButton;
