import React from 'react';
import clinic from "../assets/clinic.jpg";

const AboutUs = () => {
  return (
    <div className="bg-white py-12" id='about'>
      <div className="container mx-auto px-4 md:px-8 flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <img
            src={clinic}
            alt="Nossa Clínica"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
        <div className="w-full md:w-1/2 md:pl-8">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Sobre Nós</h2>
          <p className="text-gray-700 text-lg">
            Desde janeiro de 2006, nossa Clínica Odontológica oferece tratamentos odontológicos de ponta com foco em conforto e acessibilidade para os moradores da região. Utilizamos as técnicas mais modernas, como aparelhos invisíveis, lentes de contato dentais, implantes, próteses sobre implantes e clareamento dental, além de fornecer tratamentos tradicionais, como extrações, obturações, tratamento de canal e profilaxia. Nossa missão é garantir que cada paciente receba um atendimento sem dor e com máxima qualidade.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
