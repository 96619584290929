import React from 'react';
import Header from "./components/Header";
import WhatsAppButton from "./components/WhatsAppButton";
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Team from './components/Team';
import Services from './components/Services';
import Appointment from './components/Appointment';

function App() {
  return (
    <div>
      <Header />
      
      
      
        <Services />
        <Team />
      <AboutUs />
      
      
      <Appointment />
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

export default App;
